// Dependencies - Framework
import { LOCAL_STORAGE } from '@/globals';
import { useSessionStore } from '@/stores/sessionStore';

// Interfaces/Types - Session Data
export interface SessionData {
    aud: string[]; // Audiences.
    email: { address: string; is_primary: boolean; is_verified: boolean };
    exp: number; // Expire at to the second.
    iat: number; // Issued at to the second.
    session_id: string;
    sub: string; // User identifier.
    token: string;
}

// Interfaces/Types - User
interface UserConfig {
    created_at: string;
    email: string;
    id: string;
    updated_at: string;
    webauthn_credentials: unknown;
}

// Constants
const EXPIRE_INTERVAL_FAST = 1000; // Milliseconds (1 second).
const EXPIRE_INTERVAL_SLOW = 300000; // Milliseconds (5 minutes).

// Variables
let sessionExpiryTimer: number | undefined;

// Utilities - Initialise
export const initialise = async (): Promise<void> => {
    try {
        const sessionStore = useSessionStore();
        const sessionToken = getSessionToken();
        const sessionData = getSessionDataFromToken(sessionToken);
        if (sessionData) {
            // It is possible that the token has not expired, but the session has been terminated.
            const hankoApiUrl = import.meta.env.PROD ? import.meta.env.VITE_HANKO_API_URL_PROD : import.meta.env.VITE_HANKO_API_URL_DEV;
            const response = await fetch(`${hankoApiUrl}/sessions/validate`, { headers: { Authorization: `Bearer ${sessionData.token}` } });
            if (response.ok) {
                const result = await response.json();
                if (result.is_valid) return sessionStore.set(sessionData);
            }
        }
        clear();
    } catch (error) {
        let errorMessage = error ? (error as Error).message : undefined;
        if (errorMessage && !errorMessage.endsWith('.')) errorMessage += '.';
        // TODO: alert(`Failed to initialise session manager.${errorMessage ? ` ${errorMessage}` : ''}`);
        clear();
    }
};

// Utilities - Clear
const clear = (): void => {
    clearExpiryTimer();
    removeSessionToken();
    useSessionStore().clear();
};

// Utilities - Sign Out
export const signOut = async (): Promise<void> => {
    const sessionToken = useSessionStore().token;
    if (sessionToken) {
        const hankoApiUrl = import.meta.env.PROD ? import.meta.env.VITE_HANKO_API_URL_PROD : import.meta.env.VITE_HANKO_API_URL_DEV;
        const response = await fetch(`${hankoApiUrl}/logout`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${sessionToken}` }
        });
        if (!response.ok) {
            console.log('ERROR', await response.text());
        }
    }
    clear();
};

/**********/

// EXPIRY TIMER: Utilities - Clear Expiry Timer
const clearExpiryTimer = (): void => {
    window.clearInterval(sessionExpiryTimer);
    sessionExpiryTimer = undefined;
};

// EXPIRY TIMER: Utilities - Check Token Expiration
const checkTokenExpiration = (expiresAt: number): number => {
    const currentTime = Date.now();
    const expiresIn = expiresAt - currentTime;
    useSessionStore().expiresIn = Math.floor(expiresIn / 1000);
    if (expiresIn <= 0) return clearExpiryTimer(), 0;
    return expiresIn;
};

// EXPIRY TIMER: Utilities - Start Expiry Timer
export const startExpiryTimer = (runQuickly: boolean = false): void => {
    clearExpiryTimer();
    const expiresAt = useSessionStore().expiresAt * 1000; // Convert to milliseconds.
    const expiresIn = checkTokenExpiration(expiresAt);
    if (expiresIn) sessionExpiryTimer = window.setInterval(() => checkTokenExpiration(expiresAt), runQuickly ? EXPIRE_INTERVAL_FAST : EXPIRE_INTERVAL_SLOW);
};

/**********/

// SESSION TOKEN: Utilities - Get Session Data From Token
const getSessionDataFromToken = (token: string | null): SessionData | undefined => {
    try {
        if (!token) return undefined;

        const [, payload] = token.split('.');
        if (!payload) return undefined;

        const decodedPayload = window.atob(payload.replace(/-/g, '+').replace(/_/g, '/')); // Decode Base64URL payload by replacing URL-safe characters and using atob.
        const parsedPayload = JSON.parse(decodedPayload) as { exp: number }; // Parse decoded payload to JSON.
        const currentTime = Math.floor(Date.now() / 1000);
        const expiresInSeconds = parsedPayload.exp - currentTime;
        if (expiresInSeconds <= 60) return undefined;

        return { ...parsedPayload, token } as SessionData;
    } catch {
        return undefined;
    }
};

// SESSION TOKEN: Utilities - Get Session Token
const getSessionToken = (): string | null => localStorage.getItem(LOCAL_STORAGE.SESSION.TOKEN);

// SESSION TOKEN: Utilities - Remove Session Token
const removeSessionToken = (): void => localStorage.removeItem(LOCAL_STORAGE.SESSION.TOKEN);

// SESSION TOKEN: Utilities - Set Session Token
export const setSessionToken = (sessionToken: string | null) => {
    if (!sessionToken) return clear();
    localStorage.setItem(LOCAL_STORAGE.SESSION.TOKEN, sessionToken);
    const sessionData = getSessionDataFromToken(sessionToken);
    if (!sessionData) return clear();
    useSessionStore().set(sessionData);
};

/**********/

// USER: Utilities - Get Current User Identifier
const getCurrentUserId = async (): Promise<string | undefined> => {
    const hankoApiUrl = import.meta.env.PROD ? import.meta.env.VITE_HANKO_API_URL_PROD : import.meta.env.VITE_HANKO_API_URL_DEV;
    const sessionStore = useSessionStore();
    const response = await fetch(`${hankoApiUrl}/me`, { headers: { Authorization: `Bearer ${sessionStore.token}` } });
    if (!response.ok) {
        console.log('ERROR', await response.text());
        return undefined;
    }
    const result = await response.json();
    return result.id;
};

// USER: Utilities - Get User
const getUser = async (userId: string): Promise<UserConfig | undefined> => {
    const hankoApiUrl = import.meta.env.PROD ? import.meta.env.VITE_HANKO_API_URL_PROD : import.meta.env.VITE_HANKO_API_URL_DEV;
    const sessionStore = useSessionStore();
    const response = await fetch(`${hankoApiUrl}/users/${userId}`, { headers: { Authorization: `Bearer ${sessionStore.token}` } });
    if (!response.ok) {
        console.log('ERROR', await response.text());
        return;
    }
    const result = await response.json();
    return result;
};
