<script lang="ts" setup>
// Dependencies - Vendor
import { computed, onErrorCaptured, onMounted, watch } from 'vue';
import { useNetwork } from '@vueuse/core';

// Dependencies - Framework
import '@/assets/main.css';
import { API_ENDPOINTS } from '@/queries';
import { connectToWebSocket } from '@/services';
import { handleError } from '@/globals';
import { t } from '@/locales';
import { useAlertStore } from '@/stores/alertStore';
import { useInternetStore } from '@/stores/internetStore';
import { useSessionStore } from '@/stores/sessionStore';
import { useWorkbenchStore } from '@/stores/workbenchStore';

// Dependencies - Component
import LoadingMask from '@/components/LoadingMask.vue';

// Global State
const alertStore = useAlertStore();
const internetStore = useInternetStore();
const sessionStore = useSessionStore();
const workbenchStore = useWorkbenchStore();

// Constants
const ALERT_EXPIRES_IN = 5000;

// Non-Reactive Variables
let servicesWebSocket: WebSocket | undefined;

// Reactive Variables & Watchers - Network Connection Status
const { isOnline } = useNetwork(); // Reactive reference to the browsers network information object.
watch(isOnline, (newValue, oldValue): void => {
    // Check if the network connection online status has shifted from false to true (the network connection is now online).
    if (oldValue === false && newValue === true) {
        // The network connection is online; however, this does not mean we have an active internet connection, so attempt to access the Data Positioning Operations service.
        // The Application Monitor (appMonitor/index.ts) monitors all internet requests and will update the internet connection status reactive reference (see below) accordingly.
        // We do not need to wait for the fetch to finish and can ignore any errors.
        fetch(API_ENDPOINTS.OPERATIONS.CONFIG).catch((error): boolean => handleError(error, { locator: 'App.watch.isOnline.1' }, true));
        return;
    }
    // The network connection is offline.
    internetStore.internetConnectionIsOnline = false;
});

// Reactive Variables & Watchers - Internet Connection Status
const internetConnectionIsOnline = computed<boolean | undefined>((): boolean | undefined => internetStore.internetConnectionIsOnline); // Reactive reference to the internet connection status.
watch(internetConnectionIsOnline, (newValue, oldValue): void => {
    // Check if the internet connection online status was unset (the application is loading) and is now true (the internet connection is online).
    if (oldValue === undefined && newValue) {
        // No need to display an internet connection alert or reconnect to the Services WebSocket.
        return;
    }

    // Remove any active internet connection alert so we can replace it with one showing the new status.
    alertStore.alertConfigs = alertStore.alertConfigs.filter((alert): boolean => alert.categoryId !== 'offline' && alert.categoryId !== 'online');

    // Check if the internet connection online status has shifted from false to true (the internet connection is now online).
    if (oldValue === false && newValue === true) {
        // The internet connection is online. Connect to the Services WebSocket and show the online alert.
        if (!servicesWebSocket || (servicesWebSocket && (servicesWebSocket.readyState === WebSocket.CLOSED || servicesWebSocket.readyState === WebSocket.CLOSING))) {
            servicesWebSocket = connectToWebSocket();
        }
        alertStore.showAlert({ categoryId: 'online', expiresIn: ALERT_EXPIRES_IN, message: t('alert.internetConnectionOnlineMessage'), kind: 'positive' });
        return;
    }

    // Show the offline alert.
    alertStore.showAlert({ categoryId: 'offline', message: t('alert.internetConnectionOfflineMessage'), kind: 'negative' });
});

// Reactive Variables & Watchers - Workbench
const workbenchIsEUInstance = computed<boolean | undefined>((): boolean | undefined => workbenchStore.appIsEUInstance);
watch(workbenchIsEUInstance, (newValue): void => {
    if (newValue !== undefined) {
        connectToWebSocket();
    }
});
const workbenchIsLoading = computed<boolean>((): boolean => workbenchStore.appIsLoading);

// Lifecycle Event Handlers
onErrorCaptured((error): boolean => {
    return handleError(error, { locator: 'App.1' });
});
onMounted((): void => {
    const boundingClientRect = document.documentElement.getBoundingClientRect();
    workbenchStore.handleViewportSizeChange(boundingClientRect.height, boundingClientRect.width);
    window.addEventListener(
        'resize',
        (): void => {
            const boundingClientRect = document.documentElement.getBoundingClientRect();
            workbenchStore.handleViewportSizeChange(boundingClientRect.height, boundingClientRect.width);
        },
        {}
    );
});

// UI Event Handlers - Window/Document - Before Unload - This is triggered when the current window, contained document, and associated resources are about to be unloaded.
window.addEventListener(
    'beforeunload',
    (event): void => {
        // Check if updates are pending.
        if (sessionStore.updatesArePending) {
            // Updates are pending. Trigger browser alert allowing user to cancel unload.
            event.preventDefault();
        }
    },
    {}
);
</script>

<template>
    <!-- Application Loading Mask -->
    <!-- <Transition appear name="fade-panel">
        <LoadingMask v-if="workbenchIsLoading" type-label="Application" />
    </Transition> -->

    <!-- Top Level Router View -->
    <RouterView />
</template>
