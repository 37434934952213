// Dependencies - Vendor
import { defineStore } from 'pinia';
import { ref } from 'vue';

// Dependencies - Component
import type { AlertConfig } from '@/components/AlertBox.vue';

// Alert Store - Compose & Expose
export const useAlertStore = defineStore('alert', () => {
    // Properties & Variables
    const alertConfigs = ref<AlertConfig[]>([]); // This cannot be shallow as individual alerts are added and removed as required.

    // Methods - Show Alert
    const showAlert = (alertConfig: AlertConfig): void => {
        alertConfigs.value.push({ ...alertConfig, id: crypto.randomUUID() });
    };

    // Exposures
    return {
        alertConfigs,
        showAlert
    };
});
