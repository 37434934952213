// Dependencies - Vendor
import { ref, shallowRef } from 'vue';
import { defineStore } from 'pinia';

// Dependencies - Framework Types
import { type SessionData, startExpiryTimer } from '@/sessionManager';
import type { ConnectionConfig } from '@datapos/datapos-share-core';

// Session Store - Compose & Expose
export const useSessionStore = defineStore('session', () => {
    // Properties & Variables
    const connectionConfigs = shallowRef<ConnectionConfig[]>([]);
    const expiresAt = ref<number>(0); // Measured in seconds.
    const expiresIn = ref<number>(0); // Measured in seconds.
    const id = ref<string | undefined>(undefined);
    const initiatedAt = ref<number>(0); // Measured in seconds.
    const isActive = ref<boolean | undefined>(undefined);
    const lifetime = ref<number>(0); // Measured in seconds.
    const token = ref<string | undefined>(undefined);
    const updatesArePending = ref<boolean>(false);
    const userEmailAddress = ref<string | undefined>(undefined);
    const userEmailIsPrimary = ref<boolean | undefined>(undefined);
    const userEmailIsVerified = ref<boolean | undefined>(undefined);
    const userId = ref<string | undefined>(undefined);

    // Methods: Clear
    const clear = (): void => {
        window.document.removeEventListener('visibilitychange', resetExpiryTimer);
        expiresAt.value = 0;
        expiresIn.value = 0;
        id.value = undefined;
        initiatedAt.value = 0;
        isActive.value = false;
        lifetime.value = 0;
        token.value = undefined;
        userEmailAddress.value = undefined;
        userEmailIsPrimary.value = undefined;
        userEmailIsVerified.value = undefined;
        userId.value = undefined;
    };

    // Methods: Set
    const set = (sessionData: SessionData | undefined): void => {
        if (!sessionData) return clear();

        expiresAt.value = sessionData.exp;
        expiresIn.value = 0;
        id.value = sessionData.session_id;
        initiatedAt.value = sessionData.iat;
        isActive.value = true;
        lifetime.value = expiresAt.value - initiatedAt.value;
        token.value = sessionData.token;
        userEmailAddress.value = sessionData.email.address;
        userEmailIsPrimary.value = sessionData.email.is_primary;
        userEmailIsVerified.value = sessionData.email.is_verified;
        userId.value = sessionData.sub;
        window.document.addEventListener('visibilitychange', resetExpiryTimer);
    };

    // Utilities - Reset ExpressionTimer
    const resetExpiryTimer = () => startExpiryTimer();

    // Exposures
    return {
        connectionConfigs,
        expiresAt,
        expiresIn,
        initiatedAt,
        isActive,
        lifetime,
        token,
        updatesArePending,
        userEmailAddress,
        userEmailIsPrimary,
        userEmailIsVerified,
        userId,
        clear,
        set
    };
});
