<script lang="ts" setup>
// Dependencies - Vendor
import { onUnmounted, ref, watchEffect } from 'vue';
// import debounce from 'lodash/debounce';

// Dependencies - Framework
import { buildComponentClasses, type ComponentTypeConfig, DEFAULT_DEBOUNCE_DELAY } from '@/globals';

// Interfaces/Types - Simple Button Kind
export type SimpleButtonKind =
    | 'accordian'
    | 'alert.caution'
    | 'alert.info'
    | 'alert.negative'
    | 'alert.neutral'
    | 'alert.positive'
    | 'close.caution'
    | 'close.info'
    | 'close.negative'
    | 'close.neutral'
    | 'close.positive'
    | 'default.caution'
    | 'default.info'
    | 'default.negative'
    | 'default.neutral'
    | 'default.positive'
    | 'dropdown'
    | 'link'
    | 'link.negative'
    | 'option.caution'
    | 'option.drawer'
    // | 'option.info'
    // | 'option.negative'
    | 'option.neutral'
    // | 'option.positive'
    | 'tab';

// Attributes, Emitted Events, Options, Properties & Slots
const { kind = 'default.neutral' } = defineProps<{ kind?: SimpleButtonKind }>();
defineSlots<{ default(): unknown }>();
// const emit = defineEmits<(event: 'click', originalEvent: MouseEvent) => void>();

// Constants
const COMMON_DISABLED_CLASSES = 'disabled:pointer-events-none disabled:opacity-50';
const COMMON_FOCUS_RING_CLASSES = 'ring-offset-2 focus:ring-1 focus:ring-zinc-400 focus:ring-offset-surface-light dark:focus:ring-zinc-400 dark:focus:ring-offset-surface-dark';
const CONFIGURATION: ComponentTypeConfig = {
    accordian: {
        base: 'cursor-pointer',
        variants: {
            '': {
                '': '',
                bg: 'bg-transparent dark:bg-zinc-700',
                border: '',
                text: '',
                hover: 'hover:bg-transparent dark:hover:bg-zinc-600',
                active: 'active:bg-zinc-200 dark:active:bg-zinc-700',
                selected: ''
            }
        }
    },
    alert: {
        base: 'rounded-full border px-2.5 py-0.5 text-sm font-medium active:bg-transparent tap-transparent dark:tap-transparent',
        variants: {
            caution: { border: 'border-amber-700', hover: 'hover:bg-amber-200', text: 'text-amber-700' },
            info: { border: 'border-sky-700', hover: 'hover:bg-sky-200', text: 'text-sky-700' },
            negative: { border: 'border-red-700', hover: 'hover:bg-red-200', text: 'text-red-700' },
            neutral: { border: 'border-zinc-700', hover: 'hover:bg-zinc-200', text: 'text-zinc-700' },
            positive: { border: 'border-emerald-700', hover: 'hover:bg-emerald-200', text: 'text-emerald-700' }
        }
    },
    close: {
        base: 'p-0.5 rounded-full active:bg-transparent border border-transparent hover:border',
        variants: {
            caution: { text: 'text-amber-700', hover: 'hover:bg-amber-200 hover:border-amber-300' },
            info: { text: 'text-sky-700', hover: 'hover:bg-sky-200 hover:border-sky-300' },
            negative: { text: 'text-red-700', hover: 'hover:bg-red-200 hover:border-red-300' },
            neutral: { text: 'text-zinc-700', hover: 'hover:bg-zinc-200 hover:border-zinc-300' },
            positive: { text: 'text-emerald-700', hover: 'hover:bg-emerald-200 hover:border-emerald-300' }
        }
    },
    default: {
        base: `h-10 justify-center rounded ${COMMON_DISABLED_CLASSES} ${COMMON_FOCUS_RING_CLASSES}`,
        variants: {
            caution: {
                bg: 'bg-amber-200 dark:bg-amber-200',
                text: 'text-text-light',
                hover: 'hover:bg-amber-300 dark:hover:bg-amber-300',
                active: 'active:bg-amber-200 dark:active:bg-amber-200 tap-caution-light dark:tap-caution-dark'
            },
            info: {
                bg: 'bg-sky-200 dark:bg-sky-300',
                text: 'text-text-light',
                hover: 'hover:bg-sky-300 dark:hover:bg-sky-400',
                active: 'active:bg-sky-200 dark:active:bg-sky-300 tap-info-light dark:tap-info-dark'
            },
            negative: {
                bg: 'bg-red-200 dark:bg-red-300',
                text: 'text-text-light',
                hover: 'hover:bg-red-300 dark:hover:bg-red-400',
                active: 'active:bg-red-200 dark:active:bg-red-300 tap-negative-light dark:tap-negative-dark'
            },
            neutral: {
                bg: 'bg-zinc-100 dark:bg-zinc-400',
                text: 'text-text-light',
                hover: 'hover:bg-zinc-300 dark:hover:bg-zinc-500',
                active: 'active:bg-zinc-100 dark:active:bg-zinc-300 tap-neutral-light dark:tap-neutral-dark'
            },
            positive: {
                bg: 'bg-green-200 dark:bg-green-300',
                text: 'text-text-light',
                hover: 'hover:bg-green-300 dark:hover:bg-green-400',
                active: 'active:bg-green-200 dark:active:bg-green-300 tap-positive-light dark:tap-positive-dark'
            }
        }
    },
    dropdown: {
        base: `h-10 rounded px-3 ${COMMON_DISABLED_CLASSES} ${COMMON_FOCUS_RING_CLASSES}`,
        variants: {
            '': {
                '': '',
                bg: 'bg-surface-light dark:bg-surface-dark',
                border: '',
                text: '',
                hover: 'hover:bg-zinc-200 dark:hover:bg-zinc-700',
                active: 'active:bg-surface-light dark:active:bg-surface-dark',
                selected: ''
            }
        }
    },
    link: {
        base: `${COMMON_DISABLED_CLASSES}`,
        variants: {
            '': {
                '': '',
                bg: 'bg-transparent dark:bg-transparent',
                border: '',
                text: 'font-medium text-[#4b8bbe] dark:text-blue-300',
                hover: 'hover:text-blue-800 hover:no-underline dark:hover:text-blue-400',
                active: 'active:text-blue-600 dark:active:text-blue-300',
                selected: ''
            },
            negative: {
                '': '',
                bg: 'bg-transparent dark:bg-transparent',
                border: '',
                text: 'text-red-600 dark:text-red-300',
                hover: 'hover:text-red-800 hover:no-underline dark:hover:text-red-400',
                active: 'active:text-red-600 dark:active:text-red-300',
                selected: ''
            }
        }
    },
    option: {
        base: `px-1 flex h-10 items-center rounded ${COMMON_DISABLED_CLASSES} ${COMMON_FOCUS_RING_CLASSES}`,
        variants: {
            caution: {
                bg: 'bg-amber-200 dark:bg-amber-200',
                text: 'dark:text-text-light',
                hover: 'hover:bg-amber-300 dark:hover:bg-amber-300',
                active: 'active:bg-amber-200 dark:active:bg-amber-200 tap-caution-light dark:tap-caution-dark'
            },
            drawer: {
                hover: 'hover:bg-zinc-200 dark:hover:bg-zinc-600',
                active: 'active:bg-zinc-100 dark:active:bg-zinc-700 tap-neutral-light dark:tap-neutral-dark',
                selected: {
                    '': 'data-[selected=true]:bg-slate-300 dark:data-[selected=true]:bg-slate-600',
                    hover: 'hover:data-[selected=true]:bg-slate-200 dark:hover:data-[selected=true]:bg-slate-500'
                }
            },
            info: {
                bg: 'bg-sky-200 dark:bg-sky-300',
                text: 'dark:text-text-light',
                hover: 'hover:bg-sky-300 dark:hover:bg-sky-400',
                active: 'active:bg-sky-200 dark:active:bg-sky-300 tap-info-light dark:tap-info-dark'
            },
            negative: {
                bg: 'bg-red-200 dark:bg-red-300',
                text: 'dark:text-text-light',
                hover: 'hover:bg-red-300 dark:hover:bg-red-400',
                active: 'active:bg-red-200 dark:active:bg-red-300 tap-negative-light dark:tap-negative-dark'
            },
            neutral: {
                bg: 'bg-zinc-100 dark:bg-zinc-400',
                hover: 'hover:bg-zinc-300 dark:hover:bg-zinc-500',
                active: 'active:bg-zinc-100 dark:active:bg-zinc-300 tap-neutral-light dark:tap-neutral-dark'
            },
            positive: {
                bg: 'bg-green-200 dark:bg-green-300',
                text: 'dark:text-text-light',
                hover: 'hover:bg-green-300 dark:hover:bg-green-400',
                active: 'active:bg-green-200 dark:active:bg-green-300 tap-positive-light dark:tap-positive-dark'
            }
        }
    },
    tab: {
        base: `${COMMON_DISABLED_CLASSES}`,
        variants: {
            '': {
                '': '',
                bg: '',
                border: '',
                text: '',
                hover: '',
                active: '',
                selected: ''
            }
        }
    }
};

// Reactive Variables & Watchers
const classes = ref<string | undefined>(undefined);
watchEffect((): void => {
    classes.value = buildComponentClasses(CONFIGURATION, kind);
});

// // Event Handlers - Touch Start
// const handleTouchStart = (event: TouchEvent): void => {
//     (event.currentTarget as HTMLElement).dataset.selected = 'true';
// };

// // Event Handlers - Touch End
// const handleTouchEnd = (event: TouchEvent): void => {
//     (event.currentTarget as HTMLElement).dataset.selected = 'false';
// };

// // Lifecycle Event Handlers
// onUnmounted((): void => {
//     handleClick.cancel();
// });

// // UI Event Handlers - Handle Click
// const handleClick = debounce((event): void => emit('click', event), DEFAULT_DEBOUNCE_DELAY);
</script>

<template>
    <button class="cursor-pointer focus:outline-none" :class="classes" type="button">
        <slot />
    </button>
</template>
