<script setup lang="ts">
// Dependencies - Vendor
import { computed, onMounted, ref } from 'vue';

// Dependencies - Framework
import { reloadWorkbench } from '@/globals';
import { useInternetStore } from '@/stores/internetStore';
import { useWorkbenchStore } from '@/stores/workbenchStore';
import { version } from '@/config.json';

// Dependencies - Component
import SimpleButton from '@/components/SimpleButton.vue';

// Attributes, Emitted Events, Options, Properties & Slots
const { error } = defineProps<{ error?: { message: string } }>();

// Global State
const internetStore = useInternetStore();
const workbenchStore = useWorkbenchStore();

// Reactive Variables & Watchers
const internetConnectionIsOnline = computed<boolean | undefined>((): boolean | undefined => internetStore.internetConnectionIsOnline);
const message = ref<string | undefined>(undefined);

// Lifecycle Event Handlers
onMounted((): void => {
    message.value = buildMessage();
});

// Utilities - Build Message
const buildMessage = (): string => {
    if (!error || !error.message) {
        return `<p>Failed to load module for unspecified reason.</p><p>Click the Reload button to refresh the Workbench.</p>`;
    }

    const errorMessage = `${error.message}${error.message.endsWith('.') ? '' : '.'}`;

    if (workbenchStore.appLastDeployedVersion && workbenchStore.appLastDeployedVersion !== version && errorMessage.startsWith('Failed to fetch dynamically imported module:')) {
        return `<p>You are using a old version (${version}) of the Workbench.</p><p>${errorMessage} This version of the module was not previously downloaded and is no longer available.</p><p>Click the Reload button to update the Workbench to the latest version (${workbenchStore.appLastDeployedVersion}).</p>`;
    }

    if (!internetConnectionIsOnline.value) {
        return `<p>Your internet connection appears to be offline.</p><p>${errorMessage}</p><p>Ensure you have an active internet connection, then click the Reload button to refresh the Workbench.</p>`;
    }

    return `<p>${errorMessage}</p><p>Click the Reload button to refresh the Workbench.</p>`;
};
</script>

<template>
    <div class="bg-surface-light text-caption-light dark:bg-surface-dark dark:text-caption-dark flex h-full flex-col items-center justify-center overflow-y-scroll overscroll-y-none pb-10">
        <div class="flex max-w-80 flex-col items-center gap-y-4">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="max-w-[calc(100%-32px)] text-sm break-words" v-html="message" />
            <SimpleButton class="flex-none" kind="default.info" @click.stop="reloadWorkbench">Reload</SimpleButton>
        </div>
    </div>
</template>
