// Dependencies - Vendor
import { defineStore } from 'pinia';
import { ref } from 'vue';

// Dependencies - Framework
import { LOCAL_STORAGE } from '@/globals';

// Dependencies - Components
import type { ToolPanelId } from '@/views/toolMenu/ToolTabs.vue';

// Interfaces/Types - Drawer State Identifier
export type DrawerStateId = 'collapsed' | 'expanded' | 'floating';

// Interfaces/Types - Width Identifier
export type WidthId = 'narrow' | 'wide'; // The width of the the drawer or viewport when it is displayed. This value is not updated for drawers when they are collapsed or floating.

// Constants
const DEFAULT_DELAY = 200;
const DRAWER_MAXIMUM_WIDTH = 641; // TODO: Remove the border width.
const DRAWER_MEDIAN_WIDTH = 321; // TODO: Remove the border width.
export const DRAWER_MINIMUM_WIDTH = 76;
const VIEWPORT_BREAK_POINT = 1280;

// Workbench Store - Compose & Expose
export const useWorkbenchStore = defineStore('workbench', () => {
    // Utilities - Set Tool Drawer Width
    const setToolDrawerWidth = (): void => {
        // If the tool drawer is collapsed, set the footprint and width to zero, then exit.
        if (toolDrawerStateId.value === 'collapsed') {
            toolDrawerFootprint.value = 0;
            toolDrawerWidth.value = 0;
            return;
        }
        // The tool drawer is expanded. Set the footprint and width using the drawer width identifier.
        switch (toolDrawerWidthId.value) {
            case 'wide':
                toolDrawerFootprint.value = DRAWER_MAXIMUM_WIDTH;
                toolDrawerWidth.value = DRAWER_MAXIMUM_WIDTH;
                break;
            default:
                toolDrawerFootprint.value = DRAWER_MEDIAN_WIDTH;
                toolDrawerWidth.value = DRAWER_MEDIAN_WIDTH;
                break;
        }
    };

    // Properties & Variables
    const appIsEUInstance = ref<boolean | undefined>(undefined);
    const appIsLoading = ref<boolean>(false);
    let appIsLoadingTimeout: number | undefined = window.setTimeout((): boolean => (appIsLoading.value = true), DEFAULT_DELAY);
    const appLastDeployedVersion = ref<string | undefined>(undefined);

    const moduleIsLoading = ref<boolean>(false);

    const navDrawerActiveItemDepth = ref<number>(0);
    const navDrawerActiveItemId = ref<string | undefined>(undefined);
    const navDrawerActiveItemLevels = ref<string[]>([]); // Cannot be 'shallowRef' as individual entries are added and removed as required.
    const navDrawerFootprint = ref<number>(0);
    const navDrawerStateId = ref<DrawerStateId>('expanded');
    const navDrawerWidth = ref<number>(0);
    const navDrawerWidthId = ref<WidthId>(window.localStorage.getItem(LOCAL_STORAGE.NAV_DRAWER.WIDTH_ID) === 'wide' ? 'wide' : 'narrow');

    const navMenuFootprint = ref<number>(DRAWER_MINIMUM_WIDTH);
    const navMenuWidth = ref<number>(DRAWER_MINIMUM_WIDTH);

    const toolDrawerActiveItemId = ref<string | undefined>(window.localStorage.getItem(LOCAL_STORAGE.TOOL_DRAWER.PANEL_ID) || undefined);
    const toolDrawerFootprint = ref<number>(0);
    const toolDrawerStateId = ref<DrawerStateId>(window.localStorage.getItem(LOCAL_STORAGE.TOOL_DRAWER.STATE_ID) === 'expanded' ? 'expanded' : 'collapsed');
    const toolDrawerWidth = ref<number>(0);
    const toolDrawerWidthId = ref<WidthId>(window.localStorage.getItem(LOCAL_STORAGE.TOOL_DRAWER.WIDTH_ID) === 'wide' ? 'wide' : 'narrow');

    const toolMenuDataActionsEnabled = ref<boolean>(false);
    const toolMenuDropdownIsOpen = ref<boolean>(false);
    const toolMenuFootprint = ref<number>(DRAWER_MINIMUM_WIDTH);
    const toolMenuWidth = ref<number>(DRAWER_MINIMUM_WIDTH);

    const viewportHeight = ref<number>(0);
    const viewportTitle = ref<string | undefined>(undefined);
    const viewportWidth = ref<number>(0);
    const viewportWidthId = ref<WidthId>('wide');

    setToolDrawerWidth();

    // MASK: Methods - Clear Mask Timeout
    const clearMaskTimeout = (): void => {
        window.clearTimeout(appIsLoadingTimeout);
        appIsLoading.value = false;
        appIsLoadingTimeout = undefined;
    };

    // NAVIGATION MENU/DRAWER: Methods - Set Navigation Menu & Drawer Widths
    const setNavMenuDrawerWidths = (drawerWidth: number): void => {
        const adjustedDrawerWidth = drawerWidth ? drawerWidth + 1 : 0; // Add width of left border if the drawer has a width (i.e. it is not collapsed).
        navDrawerFootprint.value = viewportWidthId.value === 'narrow' ? 0 : adjustedDrawerWidth;
        navDrawerWidth.value = adjustedDrawerWidth;
        navMenuFootprint.value = viewportWidthId.value === 'narrow' ? DRAWER_MINIMUM_WIDTH : adjustedDrawerWidth;
        navMenuWidth.value = Math.max(DRAWER_MINIMUM_WIDTH, adjustedDrawerWidth); // Menu must be wide enough to display the toggle button even if the drawer is collapsed (ie. adjusted width of 0).
    };

    // NAVIGATION MENU/DRAWER: Methods - Toggle Navigation Drawer
    const toggleNavDrawer = (): void => {
        // If the viewport is wide, toggle the width identifier, save it to local storage and exit.
        if (viewportWidth.value >= VIEWPORT_BREAK_POINT) {
            navDrawerWidthId.value = navDrawerWidthId.value === 'narrow' ? 'wide' : 'narrow';
            window.localStorage.setItem(LOCAL_STORAGE.NAV_DRAWER.WIDTH_ID, navDrawerWidthId.value);
            return;
        }
        // The viewport is narrow. If the navigation drawer is collapsed, float it and exit.
        if (navDrawerStateId.value === 'collapsed') {
            navDrawerStateId.value = 'floating';
            return;
        }
        // Viewport is narrow and the navigation drawer is floating. Collapse the navigation drawer.
        navDrawerStateId.value = 'collapsed';
    };

    // TOOL MENU/DRAWER: Methods - Handle Tool Drawer Tab Change
    const handleToolDrawerTabChange = (toolPanelId?: ToolPanelId): void => {
        // If the viewport is wide.
        if (viewportWidth.value >= VIEWPORT_BREAK_POINT) {
            // If there is no new tool panel identifier or if it equal the active one, remember the active identifier, collapse the drawer and exit.
            if (toolPanelId === undefined || toolDrawerActiveItemId.value === toolPanelId) {
                collapseToolDrawer();
                return;
            }
            // If there is a new tool panel identifier, set the drawer width, remember the identifier and exit.
            if (!toolDrawerActiveItemId.value) {
                if (toolDrawerWidthId.value === 'wide') {
                    toolDrawerFootprint.value = DRAWER_MAXIMUM_WIDTH;
                    toolDrawerWidth.value = DRAWER_MAXIMUM_WIDTH;
                } else {
                    toolDrawerFootprint.value = DRAWER_MEDIAN_WIDTH;
                    toolDrawerWidth.value = DRAWER_MEDIAN_WIDTH;
                }
                window.localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.PANEL_ID, (toolDrawerActiveItemId.value = toolPanelId));
                if (viewportWidthId.value === 'wide') {
                    toolDrawerStateId.value = 'expanded';
                    window.localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.STATE_ID, toolDrawerStateId.value);
                } else {
                    // TODO: Is this required?
                }
                return;
            }
            // The tool panel identifier has changed. Remember the new identifier and exit.
            window.localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.PANEL_ID, (toolDrawerActiveItemId.value = toolPanelId));
            return;
        }
        // The viewport is narrow. If there is no tool panel, then collapse the drawer, remember the setting and exit.
        if (toolPanelId === undefined) {
            collapseToolDrawer();
            window.localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.PANEL_ID, '');
            return;
        }
        // The viewport is narrow and there is an active panel.
        window.localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.PANEL_ID, (toolDrawerActiveItemId.value = toolPanelId));
        toolDrawerStateId.value = 'floating';
        toolDrawerFootprint.value = 0;
        toolDrawerWidth.value = DRAWER_MEDIAN_WIDTH;
    };

    // TOOL MENU/DRAWER: Methods - Reset Tool Menu
    const resetToolMenu = (enabled: boolean): void => {
        navDrawerActiveItemDepth.value = 0;
        toolMenuDataActionsEnabled.value = enabled;
    };

    // TOOL MENU/DRAWER: Methods - Resize Tool Drawer
    const resizeToolDrawer = (): void => {
        toolDrawerWidthId.value = toolDrawerWidthId.value === 'narrow' ? 'wide' : 'narrow';
        localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.WIDTH_ID, toolDrawerWidthId.value);
        setToolDrawerWidth();
    };

    // TOOL MENU/DRAWER: Methods - Set Tool Menu Width
    const setToolMenuWidth = (width: number) => {
        toolMenuFootprint.value = viewportWidthId.value === 'narrow' ? DRAWER_MINIMUM_WIDTH : width;
        toolMenuWidth.value = Math.max(DRAWER_MINIMUM_WIDTH, width);
    };

    // TOOL MENU/DRAWER: Methods - Toggle Tool Menu - The tool menu toggle button is ony displayed when the viewport width is narrow.
    const toggleToolMenu = (): void => {
        if (toolMenuDropdownIsOpen.value || toolDrawerActiveItemId.value) {
            toolMenuDropdownIsOpen.value = false;
            handleToolDrawerTabChange();
            return;
        }
        toolDrawerStateId.value = 'floating';
        toolMenuDropdownIsOpen.value = true;
    };

    // TOOL MENU/DRAWER: Utilities - Collapse Tool Drawer
    const collapseToolDrawer = (): void => {
        toolDrawerStateId.value = 'collapsed';
        window.localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.STATE_ID, toolDrawerStateId.value);
        toolDrawerActiveItemId.value = undefined;
        window.localStorage.setItem(LOCAL_STORAGE.TOOL_DRAWER.PANEL_ID, '');
        toolDrawerFootprint.value = 0;
        toolDrawerWidth.value = 0;
    };

    // VIEWPORT: Methods - Handle Viewport Size Change
    const handleViewportSizeChange = (newViewportHeight: number, newViewportWidth: number): void => {
        // If the viewport is wide.
        if (newViewportWidth >= VIEWPORT_BREAK_POINT) {
            // If the viewport was previously narrow (i.e. width has changed from narrow to wide), configure viewport as wide.
            if (viewportWidth.value < VIEWPORT_BREAK_POINT) {
                viewportWidthId.value = 'wide';
                navDrawerStateId.value = 'expanded'; // Ensure navigation drawer is expanded.
                toolMenuDropdownIsOpen.value = false; // Ensure tool menu is closed.
                // If tool drawer was floating when viewport was narrow then ensure it is expanded when viewport is wide.
                if (toolDrawerStateId.value === 'floating') {
                    toolDrawerStateId.value = 'expanded';
                    if (toolDrawerWidthId.value === 'wide') {
                        toolDrawerFootprint.value = DRAWER_MAXIMUM_WIDTH;
                        toolDrawerWidth.value = DRAWER_MAXIMUM_WIDTH;
                    } else {
                        toolDrawerFootprint.value = DRAWER_MEDIAN_WIDTH;
                        toolDrawerWidth.value = DRAWER_MEDIAN_WIDTH;
                    }
                }
            }
            viewportHeight.value = newViewportHeight;
            viewportWidth.value = newViewportWidth;
            return;
        }
        // Viewport width is narrow. If first time (i.e. viewportWidth === 0) or viewport width has changed from wide to narrow.
        if (viewportWidth.value === 0 || viewportWidth.value >= VIEWPORT_BREAK_POINT) {
            viewportWidthId.value = 'narrow';
            navDrawerStateId.value = 'collapsed';
            collapseToolDrawer();
            viewportHeight.value = newViewportHeight;
            viewportWidth.value = newViewportWidth;
            return;
        }
        // Viewport breakpoint change did not occur.
        viewportHeight.value = newViewportHeight;
        viewportWidth.value = newViewportWidth;
    };

    // Exposures
    return {
        appIsEUInstance,
        appIsLoading,
        appLastDeployedVersion,

        moduleIsLoading,

        navDrawerActiveItemDepth,
        navDrawerActiveItemId,
        navDrawerActiveItemLevels,
        navDrawerFootprint,
        navDrawerStateId,
        navDrawerWidth,
        navDrawerWidthId,

        navMenuFootprint,
        navMenuWidth,

        toolDrawerActiveItemId,
        toolDrawerFootprint,
        toolDrawerStateId,
        toolDrawerWidth,
        toolDrawerWidthId,

        toolMenuDataActionsEnabled,
        toolMenuDropdownIsOpen,
        toolMenuFootprint,
        toolMenuWidth,

        viewportHeight,
        viewportTitle,
        viewportWidth,
        viewportWidthId,

        clearMaskTimeout,
        resetToolMenu,
        handleToolDrawerTabChange,
        handleViewportSizeChange,
        resizeToolDrawer,
        setNavMenuDrawerWidths,
        setToolMenuWidth,
        toggleNavDrawer,
        toggleToolMenu
    };
});
